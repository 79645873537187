
import { ref, defineComponent } from 'vue';
import { useRepositories } from "@/repository/repositories";
import type { ElForm } from 'element-plus';
import { ElMessage } from 'element-plus';
import { validateLoginId } from "@/util/validate";

type FormData = {
  id?: string;
}
type DataType = {
  formData: FormData;
};

export default defineComponent({
  name: "user-reset-password-confirmation",

  setup() {
    const ruleFormRef = ref<InstanceType<typeof ElForm>>();
    const repos = useRepositories();
    return {
      ruleFormRef,
      repos,
    }
  },

  data(): DataType {
    return {
      formData: {
        id: undefined,
      },
    }
  },

  computed: {
    validateMessage(): (key: string) => string {
      return (key: string): string => {
        switch (key) {
          case "id": {
            if (this.formData.id === undefined) return '';
            const { message } = validateLoginId(this.formData.id);
            return message;
          }
          default: {
            throw new Error("unexpected validation target");
          }
        }
      };
    },
  },

  methods: {
    modifyFormDataForValidate(): void {
      if (this.formData.id === undefined) {
        this.formData.id = "";
      }
    },

    isValidFormData(): boolean {
      const res = validateLoginId(this.formData.id ?? "");
      if (!res.valid) return false;
      return true;
    },


    async sendConfirmation(): Promise<void> {
      this.modifyFormDataForValidate();
      const res = this.isValidFormData();
      if (!res) {
        throw new Error(`failed validate for sending reset password confirmation. formData: ${this.formData}`);
      }

      try {
        await this.repos.auth.passwordResetConfirmation(this.formData.id ?? "");
        ElMessage({
          message: 'メール送信に成功しました。メール記載のURL有効期限は15分となります。',
          type: "success",
          duration: 0,
        });

      } catch (e) {
        console.error(e);
        ElMessage({
          message: 'メール送信に失敗しました。メールアドレスを確認し再度お試しください。',
          type: "error",
          duration: 0,
        });
        return;
      }
    }
  }
});
